{
  "logout": "Abmelden",
  "title": "Appstore",
  "back": "Zurück",
  "noSide": "Die angegebene Url konnt nicht gefunden werden",
  "name": "Name",
  "user": "Benutzer",
  "password": "Passwort",
  "login": "Anmelden",
  "loginFail": "Falscher Benutzer/Passwort. Versuchen Sie es nochmals!"
}