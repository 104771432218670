import { io } from 'socket.io-client'
let socket = null

const setSocket = (session) => {
  socket = io(process.env.NODE_ENV.trim() === 'development' ? `${location.protocol}//${location.hostname}:3000` : '', {
    auth: {
      session
    },
    query: {
      group: 'main'
    }
  })
}

export {
  socket,
  setSocket
}
