<template>
  <q-layout class="bg-blue-grey-1 window-height window-width row justify-center">
    <div class="column">
      <q-img :src="require('@/assets/logo.png')" spinner-color="white" />
      <div class="row">
        <q-card square bordered class="q-pa-lg shadow-1" style="width: 410px">
          <q-card-section>
            <q-form class="q-gutter-md" @submit="doLogin">
              <q-input
                square
                filled
                clearable
                v-model="username"
                type="text"
                :label="$t('user')"
                tabindex="1"
              />
              <q-input
                square
                filled
                clearable
                v-model="password"
                type="password"
                :label="$t('password')"
                tabindex="2"
              />
              <q-banner inline-actions class="text-white bg-red" v-if="error">{{error}}</q-banner>
              <q-card-actions style="padding: 8px 0 0 0">
                <q-btn color="light-blue-6" text-color="white" size="lg" class="full-width" :label="$t('login')" type="submit" tabindex="3" />
              </q-card-actions>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-layout>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import { login, setUser } from '../stores/user'
import { useRouter } from 'vue-router'
export default {
  name: 'Login',
  setup (_props, context) {
    const router = useRouter()
    const username = ref('')
    const password = ref('')
    const error = ref('')
    const { proxy } = getCurrentInstance()

    const doLogin = () => {
      login(username.value, password.value).then(res => {
        setUser(res.data)
        router.push({ name: 'Main' })
      }).catch(() => {
        error.value = proxy.$t('loginFail')
        setTimeout(() => {
          error.value = ''
        }, 3000)
      })
    }

    return {
      username,
      password,
      doLogin,
      error
    }
  }
}
</script>

<style>

</style>
