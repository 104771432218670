import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import i18n from './i18n'
import { api, sema } from './axios'

api.get('./settings.json').then(res => {
  if (process.env.NODE_ENV.trim() === 'development') {
    api.defaults.baseURL = `${location.protocol}//${location.hostname}:3000/api`
  } else {
    api.defaults.baseURL = '/api'
  }
  sema.defaults.baseURL = res.data.baseurl
  createApp(App).use(i18n).use(Quasar, quasarUserOptions).use(router).mount('#app')
}).catch((err) => {
  console.log(err)
})
