<template>
  <q-layout view="hHh lpR fFf">

    <q-header elevated class="bg-blue-grey-4 text-white">
      <q-toolbar>
        <q-toolbar-title>
          <q-img :src="require('@/assets/appstore_menu.png')" style="max-width: 350px; max-height: 50px"/>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <q-page class="compPage">
        <q-card class="bg-transparent no-shadow no-border">
          <q-card-section class="q-pa-none">
            <div class="row q-col-gutter-sm ">
              <div v-for="(item, index) in dashboards" :key="index" class="col-md-3 col-sm-12 col-xs-12">
                <q-item clickable @click="goToApp(item)" :style="`background-color: ${item.color1}`" class="q-pa-none q-ml-xs">
                  <q-item-section side :style="`background-color: ${item.color2}`" class=" q-pa-lg q-mr-none text-white">
                    <!-- <q-icon :name="item.icon" color="white" size="24px"></q-icon> -->
                    <q-item-label class="text-white text-h6 text-weight-bolder">{{ item.name.substring(0, 1) }}</q-item-label>
                  </q-item-section>
                  <q-item-section class=" q-pa-md q-ml-none  text-white">
                    <q-item-label class="text-white text-h6 text-weight-bolder">{{ item.name }}</q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-page>
    </q-page-container>

    <q-footer elevated class="bg-grey-8 text-white">
      <q-toolbar>
        <q-btn unelevated no-caps icon="event_note">
          <q-toolbar-title>{{user && user.username}}</q-toolbar-title>
          <q-menu>
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup>
                <q-item-section avatar>
                  <q-icon name="exit_to_app" />
                </q-item-section>
                <q-item-section @click="goLogout">{{$t('logout')}}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-toolbar>
    </q-footer>

  </q-layout>
</template>

<script>
import { ref, onMounted } from 'vue'
import { user, logout } from '../stores/user'
import { useRouter } from 'vue-router'
import { api } from '../axios'
import { socket } from '../stores/socket'
export default {
  name: 'Main',
  setup () {
    const router = useRouter()
    const dashboards = ref([])
    const colors = [{
      color1: '#5064b5',
      color2: '#3e51b5'
    }, {
      color1: '#f37169',
      color2: '#f34636'
    }, {
      color1: '#ea6a7f',
      color2: '#ea4b64'
    }, {
      color1: '#a270b1',
      color2: '#9f52b1'
    }, {
      color1: '#546bfa',
      color2: '#475AD0'
    }, {
      color1: '#3a9688',
      color2: '#2D776C'
    }, {
      color1: '#7cb342',
      color2: '#659235'
    }, {
      color1: '#f88c2b',
      color2: '#D07626'
    }]

    socket.on('folderChange', () => {
      loadDashboards()
    })

    onMounted(() => {
      console.log(user.value)
      loadDashboards()
    })

    const loadDashboards = () => {
      api.get('/dashboard').then(res => {
        console.log(res.data)
        dashboards.value = res.data.map((x, i) => {
          const index = i % colors.length
          x.color1 = colors[index].color1
          x.color2 = colors[index].color2
          return x
        })
      })
    }

    const goLogout = () => {
      logout().then(() => {
        router.push({ name: 'Login' })
      })
    }

    const goToApp = item => {
      console.log(item)
      if (item.isReferenceApp) {
        window.open(item.path, '_blank')
      } else {
        if (process.env.NODE_ENV.trim() === 'development') {
          location.href = `${location.protocol}//${location.hostname}:3000/${item.path}`
        } else {
          location.href = `/${item.path}`
        }
      }
    }

    return {
      user,
      goLogout,
      dashboards,
      goToApp
    }
  }
}
</script>

<style scoped>
  .compPage{
    height: 1px;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
</style>
