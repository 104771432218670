import { createRouter, createWebHashHistory } from 'vue-router'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import Error404 from '../views/Error404'
import { checkLogin, setUser } from '../stores/user'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/:pathMatch(.*)',
    name: 'Error',
    component: Error404
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  checkLogin().then(res => {
    setUser(res.data)
    if (to.name === 'Login') {
      next({ name: 'Main' })
    } else {
      next()
    }
  }).catch(() => {
    if (to.name === 'Login') {
      next()
    } else {
      next({ name: 'Login' })
    }
  })
})

export default router
