import { ref } from 'vue'
import { sema, api } from '../axios'
import { setSocket } from './socket'

const user = ref(null)
const checkLogin = () => {
  return sema.get('')
}

const login = (username, password) => {
  return sema.post('', { username, password, autologin: true })
}
const logout = () => {
  return sema.delete('/')
}

const setUser = u => {
  console.log(u)
  setSocket(u.sessionId)
  api.defaults.headers.common.session = u.sessionId
  user.value = u
}

export {
  user,
  checkLogin,
  login,
  logout,
  setUser
}
